<template>
  <div>
    <!-- 联赛 -->
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <el-form-item label="第一周" required label-width="200px">
        <el-col :span="5">
          <el-form-item
            prop="charge_time.first_start"
            id="charge_time.first_start"
          >
            <el-date-picker
              ref="match_time_match_start"
              type="datetime"
              placeholder="选择开始时间"
              v-model="dataForm.charge_time.first_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="charge_time.first_end">
          <el-form-item prop="charge_time.first_end">
            <el-date-picker
              ref="match_time_match_end"
              type="datetime"
              placeholder="选择结束时间"
              v-model="dataForm.charge_time.first_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="第二周" required label-width="200px">
        <el-col :span="5">
          <el-form-item
            prop="charge_time.second_start"
            id="charge_time.second_start"
          >
            <el-date-picker
              ref="match_time_match_start"
              type="datetime"
              placeholder="选择开始时间"
              v-model="dataForm.charge_time.second_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="charge_time.second_end">
          <el-form-item prop="charge_time.second_end">
            <el-date-picker
              ref="match_time_match_end"
              type="datetime"
              placeholder="选择结束时间"
              v-model="dataForm.charge_time.second_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="第三周" required label-width="200px">
        <el-col :span="5">
          <el-form-item
            prop="charge_time.third_start"
            id="charge_time.third_start"
          >
            <el-date-picker
              ref="match_time_match_start"
              type="datetime"
              placeholder="选择开始时间"
              v-model="dataForm.charge_time.third_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="charge_time.third_end">
          <el-form-item prop="charge_time.third_end">
            <el-date-picker
              ref="match_time_match_end"
              type="datetime"
              placeholder="选择结束时间"
              v-model="dataForm.charge_time.third_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="赛事规则"
        label-width="200px"
        prop="charge_rule"
        id="quillEditorQiniu"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.charge_rule"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>

      <el-form-item style="margin-top: 50px;">
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button size="large" @click="goBack()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { randomStr } from "@/utils/randomStr";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["20rpx", "24rpx", "26rpx", "32rpx", "48rpx", "custom-size"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        "#000000",
        "#e60000",
        "#ff9900",
        "#ffff00",
        "#008a00",
        "#0066cc",
        "#9933ff",
        "#ffffff",
        "#facccc",
        "#ffebcc",
        "#ffffcc",
        "#cce8cc",
        "#cce0f5",
        "#ebd6ff",
        "#bbbbbb",
        "#f06666",
        "#ffc266",
        "#ffff66",
        "#66b966",
        "#66a3e0",
        "#c285ff",
        "#888888",
        "#a10000",
        "#b26b00",
        "#b2b200",
        "#006100",
        "#0047b2",
        "#6b24b2",
        "#444444",
        "#5c0000",
        "#663d00",
        "#666600",
        "#003700",
        "#002966",
        "#3d1466",
        "custom-color",
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
  ["clean"], // remove formatting button
];

import { mapActions } from "vuex";
export default {
  name: "manager",
  components: {
    perButton,
  },
  props: {
    // view_type: {
    //   type: String,
    // },
  },
  computed: {
    isEdit: function() {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    let self = this;
    const signupEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.charge_time.first_start !== "" &&
        self.dataForm.charge_time.first_end != ""
      ) {
        let sign_up_start = new Date(self.dataForm.charge_time.first_start);
        let sign_up_end = new Date(self.dataForm.charge_time.first_end);
        if (sign_up_end <= sign_up_start) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    const reviewEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.charge_time.second_start != "" &&
        self.dataForm.charge_time.second_end != ""
      ) {
        let review_start = new Date(self.dataForm.charge_time.second_start);
        let review_end = new Date(self.dataForm.charge_time.second_end);
        if (review_end <= review_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    const matchEndFirstCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.charge_time.first_end != "" &&
        self.dataForm.charge_time.second_start != ""
      ) {
        let review_end = new Date(self.dataForm.charge_time.first_end);
        let match_start = new Date(self.dataForm.charge_time.second_start);
        if (match_start < review_end) {
          callback(new Error("第二周开始时间必须大于等于第一周结束时间"));
          return;
        }
      }

      callback();
    };

    const matchStartTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.charge_time.second_end != "" &&
        self.dataForm.charge_time.third_start != ""
      ) {
        let review_end = new Date(self.dataForm.charge_time.second_end);
        let match_start = new Date(self.dataForm.charge_time.third_start);
        if (match_start < review_end) {
          callback(new Error("第三周开始时间必须大于等于第二周结束时间"));
          return;
        }
      }

      callback();
    };

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (
        self.dataForm.charge_time.third_start != null &&
        self.dataForm.charge_time.third_end != null
      ) {
        let match_start = new Date(self.dataForm.charge_time.third_start);
        let match_end = new Date(self.dataForm.charge_time.third_end);
        if (match_end <= match_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    return {
      match_data: [],
      stage: "",
      match_id: "",
      type_id: "",
      matchType: "",
      view_type: "edit",
      type: "s1",
      org_rule: "",
      dataForm: {
        _id: "",
        charge_time: {
          first_start: null,
          first_end: null,
          second_start: null,
          second_end: null,
          third_start: null,
          third_end: null,
        },
        charge_rule: "",
      },
      rules: {
        "charge_time.first_start": [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
        ],
        "charge_time.first_end": [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: signupEndTimeCheckRule, trigger: ["change"] },
        ],
        "charge_time.second_start": [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
          { validator: matchEndFirstCheckRule, trigger: ["change", "blur"] },
        ],
        "charge_time.second_end": [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: reviewEndTimeCheckRule, trigger: ["change"] },
        ],

        "charge_time.third_start": [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
          { validator: matchStartTimeCheckRule, trigger: ["change", "blur"] },
        ],
        "charge_time.third_end": [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change"] },
        ],
        charge_rule: [
          {
            required: true,
            message: "请输入赛事规则",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOption: {
        placeholder: "请填写赛事规则",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      loading: false,
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.matchType = localStorage.getItem("matchType");
      },
      immediate: true,
    },
    match_data: {
      handler: function(val) {
        this.setFormData();
      },
      immediate: true,
    },
  },
  mounted() {
    this.type = this.$route.query.type_id;
    this.match_id = this.$route.query.match_id;
    this.stage = this.$route.query.stage;
    this.handleQuery();
  },

  methods: {
    async handleQuery() {
      const { data } = await this.$http.match.get_info_rule({
        match_id: this.match_id,
      });

      this.match_data = data.data[0];
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }
      return null;
    },
    //回显
    
    //提交
    setFormData() {
      if (this.match_data.length != 0) {
        let data = this.match_data;
        Object.keys(this.dataForm).forEach((key) => {
          if (data[key]) {
            this.dataForm[key] = data[key];
          }
        });

        this.dataForm.charge_time.first_start = this.getDateTime(
          this.match_data.charge_time.first_start
        );
        this.dataForm.charge_time.first_end = this.getDateTime(
          this.match_data.charge_time.first_end || null
        );
        this.dataForm.charge_time.second_start = this.getDateTime(
          this.match_data.charge_time.second_start || null
        );
        this.dataForm.charge_time.second_end = this.getDateTime(
          this.match_data.charge_time.second_end || null
        );
        this.dataForm.charge_time.third_start = this.getDateTime(
          this.match_data.charge_time.third_start || null
        );
        this.dataForm.charge_time.third_end = this.getDateTime(
          this.match_data.charge_time.third_end || null
        );

        this.org_rule = this.match_data.charge_rule;
      }
    },
    //返回上一次
    goBack() {
      this.removeTabByPath(`/match/info/info_s1/pointsManage`);
      this.removeTabByPath(`/match/info/info_s1/pointsManage`);
      this.$router.push({
        path: `/match/info/info_s1/pointsManage`,
        query: {
          match_id: this.match_id,
          type_id: this.type,
          stage: this.stage,
        },
      });
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          if (this.org_rule != this.dataForm.charge_rule) {
            let rule_url = await this.uploadContent(
              "rule",
              this.dataForm.charge_rule
            );
            if (!rule_url || !rule_url.length) {
              this.$message.error("保存失败, 上传赛事规则失败");
              return;
            }
          }
          console.log(1111, this.type);
          let { data, errorCode } = await this.$http.match.save_info_points(
            this.type,
            d
          );
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath(`/match/info/info_s1/pointsManage`);
          this.$router.push({
            path: `/match/info/info_s1/pointsManage`,
            query: {
              match_id: this.match_id,
              type_id: this.type,
              stage: this.stage,
            },
          });
          this.$bus.$emit("onMatchChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
            console.log("🚀 ~ file: pointsRule.vue:593 ~ this.$refs.dataForm.validate ~ this.type:", this.type)
            console.log("🚀 ~ file: pointsRule.vue:593 ~ this.$refs.dataForm.validate ~ this.type:", this.type)
            console.log("🚀 ~ file: pointsRule.vue:593 ~ this.$refs.dataForm.validate ~  this.type,:",  this.type,)
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };
      data.match_id = this.match_id;
      console.log(data);
      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },

    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            let path = uploadOssUrl().richText + this.type + "/" + fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    cancelDialog() {},
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style src="../../../editor.css"></style>
<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/*/deep/ .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
